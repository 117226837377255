<template>
  <v-container>
    <v-card>
      <v-card-title class="d-flex justify-space-around">
        <h1 class="font-weight-medium text-center">Pqrs Contraloría</h1>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-btn
              v-if="selected.length > 0"
              class="red darken-1 white--text mr-2 mt-2"
              @click="promptDeleteItem(null, true)"
            >
              <v-icon dark> mdi-trash-can </v-icon>
              Eliminar ?
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="query.text"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="query.array.length > 0 ? query.array : array_table"
          :single-select="false"
          item-key="_id"
          v-model="selected"
          :show-select="estado_select"
        >
          <template #[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              link
              @click="validateCrud(item._id, false)"
            >
              mdi-pencil
            </v-icon>

            <v-icon small class="mr-2" link @click="promptDeleteItem(item)">
              mdi-trash-can
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <ModalForm
      :visible="loader.estado"
      @close="loader.estado = false"
      :message="loader.message"
    />

    <ModalConfirm
      :message="modal_confirm.message"
      :estado="modal_confirm.estado"
      :callback="modal_confirm.callback"
      @close="modal_confirm.estado = false"
      :max_width="'300px'"
    ></ModalConfirm>
  </v-container>
</template>

<script>
import ModalForm from "../modal/ModalForm.vue";
import ModalConfirm from "../modal/ModalConfirm.vue";

export default {
  components: {
    ModalForm,
    ModalConfirm,
  },
  data: () => ({
    selected: [],
    editedIndex: -1,

    loader: {
      estado: false,
      message: "",
    },

    modal_confirm: {
      message: "",
      estado: false,
      callback: Function,
    },

    query: {
      time_out: "",
      text: "",
      array: [],
    },

    headers: [
      { text: "Numero radicado", value: "nro_rad_frpqr" },
      { text: "Fecha radicado", value: "fecha_rad" },
      { text: "Tipo de peticion", value: "tpq_nmbre" },
      { text: "Observaciones", value: "observ_frpqr" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    array_table: [],
  }),
  watch: {
    "query.text": function (val) {
      clearTimeout(this.query.time_out);

      this.query.time_out = setTimeout(() => {
        if (val && val.length > 3) this.searchItems();
        else {
          this.query.array = [];
          if (!val) this.getItems();
        }
      }, 500);
    },
  },

  computed: {
    estado_select: function () {
      let {
        array_table,
        query: { array },
      } = this;

      if (!array_table.length && !array.length) return false;
      return true;
    },
    radicado: function (tcr_cdgo, dcm_ano, dcm_cdgo) {
      return tcr_cdgo + "-" + dcm_ano + "-" + dcm_cdgo;
    },
  },

  created() {
    this.getItems();
  },

  methods: {
    getItems() {
      this.loader.message =
        "Por favor espere mientras se consultan los datos..";

      this.loader.estado = true;
      let uri = "/formatopqrs/" + String(this.$store.state.company);

      this.axios
        .get(uri)
        .then((response) => {
          this.array_table = response.data.map((e) => {
            e.fecha_rad = this.formatDate(e.f_rad_frpqr);
            return e;
          });

          this.loader.estado = false;
        })
        .catch((error) => {
          this.loader.estado = false;
          this.$emit("msj", { text: error.message });
        });
    },

    validateCrud(id = "", add = true, dcm_id = "", formatoPqrs = true) {
      this.$router.push({
        name: "CreateFormatoPqrs",
        query: { id, add, dcm_id, formatoPqrs },
      });
    },

    promptDeleteItem(item = {}, multiple = false) {
      if (!multiple) this.editedIndex = this.array_table.indexOf(item);

      this.modal_confirm = {
        message: "¿Eliminar registro(s)?",
        estado: true,
        callback: multiple ? this.confirmDeleteItems_2 : this.confirmDeleteItem,
      };
    },

    confirmDeleteItem() {
      this.modal_confirm.estado = false;

      let item = this.array_table[this.editedIndex] || {};
      let uri = "/formatopqrs/delete/" + item._id;

      this.axios
        .delete(uri)
        .then(() => {
          this.$emit("msj", { text: "Registro eliminado", estado: "success" });
          setTimeout(() => {
            //location.reload();
            this.selected = [];
            this.getItems();
          }, 1000);
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },

    confirmDeleteItems_2() {
      this.modal_confirm.estado = false;
      let registros = JSON.parse(JSON.stringify(this.selected)) || [];

      this.loader.message = "Eliminando registro(s)";
      this.loader.estado = true;

      this.axios
        .delete("/formatopqrs/deleteitems", {
          data: { delItems: registros },
        })
        .then((res) => {
          this.$emit("msj", { text: res.data.message, estado: "success" });
          this.loader.estado = false;

          setTimeout(() => {
            //location.reload();
            this.selected = [];
            this.getItems();
          }, 1000);
        })
        .catch((error) => {
          this.loader.estado = false;
          this.$emit("msj", { text: error.message });
        });
    },

    searchItems() {
      let cmp_id = String(this.$store.state.company);
      let key = this.query.text;

      this.loader.message = "Buscando...";
      this.loader.estado = true;

      this.axios
        .post("/formatopqrs/search", { cmp_id, key })
        .then((response) => {
          this.loader.estado = false;
          this.query.array = response.data.map((e) => {
            e.fecha_rad = this.formatDate(e.f_rad_frpqr);
            return e;
          });

          if (!this.query.array.length) {
            this.$emit("msj", { text: "No se encontraron coincidencias" });
          }
        })
        .catch((error) => {
          this.loader.estado = false;
          this.$emit("msj", { text: error.message });
        });
    },

    formatDate(date) {
      if (!date) return null;
      else {
        let fecha = date.substring(0, 10);

        if (fecha == "1970-01-01") return null;
        else return fecha;
      }
    },
  },
};
</script>

